import { useRouter } from "next/router";
import BackgroundImage from "src/assets/images/bg-10.jpg";
import BookAnAppointment from "src/components/Home/BookAnAppointment";
import Partner from "src/components/Home/Partner";
import Functions from "src/components/Products/ProductDetail/Functions";
import ProductDetail from "src/components/Products/ProductDetail/ProductDetail";
import TabInfo from "src/components/Products/ProductDetail/TabInfo";
import HotDeals from "src/components/Service/ServiceDetail/HotDeals";
import TBreadcrumb from "src/components/Service/ServiceDetail/TBreadcrumb";
import { routerConstant } from "src/constants/routerConstant";
import LayoutView from "src/layouts/LayoutView";
import { useGetOneProduct, useGetProductsByCategory } from "src/react-query/hooks/product";

export default function ProductDetailView() {
  const router = useRouter();

  const slugProduct = router.query?.["slug-products"];
  const productQuery = useGetOneProduct(slugProduct);
  const dataProduct = productQuery?.data?.data?.product;

  const routesBreadcrumb = [
    { id: 1, name: "Trang chủ", href: "#", active: false, separator: true },
    { id: 2, name: "Sản phẩm", href: routerConstant.products, active: false, separator: true },
    {
      id: 3,
      name: dataProduct?.name || "",
      href: dataProduct?.slug || "#",
      active: true,
      separator: true,
    },
  ];

  const productCategoriesQuery = useGetProductsByCategory({
    slug: dataProduct?.product_category?.slug,
    // eslint-disable-next-line camelcase
    page_size: 6,
    page: 1,
  });
  const dataProductCategories = (
    productCategoriesQuery?.data?.data?.listProductByCategory || []
  ).filter((d) => d.id !== dataProduct?.id);
  return (
    <LayoutView className="tw-bg-black">
      <div
        className="tw-pt-[60px] md:tw-pt-[100px] lg:tw-pt-[150px] tw-bg-black"
        style={{
          backgroundImage: `url(${BackgroundImage.src})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <div className="tw-max-w-[1300px] tw-mx-auto tw-text-white tw-px-[12px]">
          <TBreadcrumb routes={routesBreadcrumb} />

          <div className="tw-mt-[25px]">
            <ProductDetail data={dataProduct} />
          </div>
        </div>
        <TabInfo data={dataProduct} />
        {!!dataProductCategories.length && (
          <HotDeals title="Sản phẩm tương tự" data={dataProductCategories} />
        )}

        <Functions />

        <BookAnAppointment />

        <Partner />
      </div>
    </LayoutView>
  );
}
