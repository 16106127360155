/* eslint-disable @next/next/no-img-element */
import { Image, InputNumber } from "antd";
import classNames from "classnames";
import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import { SampleNextArrow, SamplePrevArrow } from "src/components/Home/SlideBanner";
import { OrderPopupState } from "src/recoil/commonRecoilState";
import { useSetRecoilState } from "recoil";
import {useRouter} from "next/router";

const settings = {
  dots: false,
  infinite: false,
  speed: 1000,
  autoplaySpeed: 3000,
  slidesToShow: 3.5,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows: true,
  autoplay: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 570,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};
export default function ProductDetail({ data }) {
  const router = useRouter();
  const openPopup = router?.query?.['popup-open']

  const [quantity, setQuantity] = useState(1);
  const parseImages = data?.images ? JSON.parse(data?.images) : [];

  const [imageIndex, setImageIndex] = useState(0);

  const setOrderPopup = useSetRecoilState(OrderPopupState);

  useEffect(() => {
    if (openPopup && data) {
      handleOrder()
    }
  }, [openPopup, data])

  const handleOrder = () => {
    setOrderPopup({
      show: true,
      order: {
        type: "Sản phẩm",
        name: data?.name,
        price: data?.price,
        id: data?.id,
        quantity,
      },
    });
  };

  return (
    <div className="tw-mb-[110px] tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-[50px] md:tw-gap-x-[40px]">
      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
        <div className="tw-mb-[25px]">
          <Image
            preview={false}
            className={classNames(
              "tw-max-h-[636px] tw-border-[1px] tw-border-solid tw-border-greyAda ",
              `tw-aspect-square tw-object-cover`,
            )}
            height={636}
            src={parseImages?.[imageIndex]}
            alt="oto"
          />
        </div>

        <div className="tw-w-full">
          <Slider {...settings} className="custom-arrow">
            {parseImages?.map((image, index) => (
              <Image
                preview={false}
                onClick={() => setImageIndex(index)}
                key={index}
                width={160}
                height={160}
                className={classNames(
                  "tw-cursor-pointer tw-border-[1px] tw-border-solid tw-border-greyAda tw-object-cover",
                  { "!tw-border-red !tw-border-[2px]": index == imageIndex },
                )}
                src={image || "https://cdnimg.vietnamplus.vn/uploaded/xtsqr/2019_01_28/a18.jpeg"}
                alt="oto"
              />
            ))}
          </Slider>
        </div>
        <style jsx global>{`
          .custom-arrow .slick-arrow > div {
            width: 30px !important;
            height: 30px !important;
          }
          .custom-arrow .slick-slide {
            display: flex;
          }
          .custom-arrow .slick-slide > div {
            margin: 0 auto;
          }
        `}</style>
      </div>

      <div className="">
        <h1 className="tw-text-[30px] tw-leading-[40px] tw-text-white tw-font-bold tw-uppercase overFlow-5-line">
          {data?.name}
        </h1>

        <h2 className="tw-text-[30px] tw-leading-[30px] tw-text-red tw-font-bold tw-uppercase tw-mb-[50px] overFlow-2-line tw">
          {Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(data?.price)}
        </h2>

        <div className="tw-leading-[24px] tw-text-[15px] tw-text-white tw-mb-[45px] tw-overflow-hidden">
          <div className="tw-container tw-mx-auto lg:tw-w-[860px] tw-text-white tw-mt-[55px] tw-text-[16px]">
            <div dangerouslySetInnerHTML={{ __html: data?.short_description }} />
          </div>
        </div>

        <div className="tw-flex tw-items-center">
          <span
            onClick={() =>
              setQuantity((pre) => {
                return pre > 1 ? pre - 1 : pre;
              })
            }
            className="tw-cursor-pointer tw-h-[32px] tw-w-[32px] tw-bg-white tw-text-black tw-flex tw-items-center tw-justify-center tw-font-bold"
          >
            {" "}
            -{" "}
          </span>
          <InputNumber
            className="input-number-center remove-step tw-text-center tw-w-[40px]"
            value={quantity}
            min={1}
            max={5}
            defaultValue={1}
          />
          <span
            onClick={() =>
              setQuantity((pre) => {
                return pre >= 5 ? pre : pre + 1;
              })
            }
            className="tw-cursor-pointer tw-h-[32px] tw-w-[32px] tw-bg-white tw-text-black tw-flex tw-items-center tw-justify-center tw-font-bold"
          >
            {" "}
            +{" "}
          </span>
        </div>
        <div>
          <button
            className="tw-w-fit tw-py-[10px] tw-px-[20px] tw-text-white tw-text-[18px] tw-text-start tw-bg-button tw-mt-[10px] custom-hover"
            onClick={handleOrder}
          >
            Đặt Hàng
          </button>
        </div>
      </div>
    </div>
  );
}
