/* eslint-disable @next/next/no-img-element */
import classNames from "classnames";
import React from "react";
import IntroServiceImage1 from "src/assets/images/intro_service_1.png";
import IntroServiceImage2 from "src/assets/images/intro_service_2.png";
import IntroServiceImage3 from "src/assets/images/intro_service_3.png";
import IntroServiceImage4 from "src/assets/images/intro_service_4.png";

const ListFunction = [
  {
    id: 1,
    icon: IntroServiceImage1.src,
    label: "Công nghệ mới nhất",
    description:
      "Hệ thống rửa xe AUTOSHINE sử dụng công nghệ chăm sóc xe thông minh mới nhất, rửa xe tự động hoàn toàn, đảm bảo xe của bạn luôn được làm sạch với chất lượng cao nhất !",
  },
  {
    id: 2,
    icon: IntroServiceImage2.src,
    label: "Nguyên liệu an toàn",
    description:
      "Dùng nguyên liệu nhập khẩu từ Đức, làm sạch vào tạo bóng, giữ màu sơn nguyên bản sạch hơn, không có vệt, an toàn cho bề mặt xe và cực kì thân thiện với môi trường.....",
  },
  {
    id: 3,
    icon: IntroServiceImage3.src,
    label: "Không trầy xước",
    description:
      "Hệ thống chổi xoay mềm cảm ứng thông minh giúp làm sạch linh hoạt theo từng bề mặt và đường viền của xe, giúp vỏ xe được làm sạch tối ưu nhất",
  },
  {
    id: 4,
    icon: IntroServiceImage4.src,
    label: "Làm sạch nhanh nhất",
    description:
      "Hệ thống thông minh, Quy trình bài bản giúp làm sạch xe một cách chính xác và không bị bỏ sót công đoạn, giúp rửa xe nhanh sạch hơn, nhanh hơn. Và nhất là bạn không cần phải chờ đợi với ứng dụng đặt lịch rửa xe AUTOSHINE APP tiện dụng",
  },
];
const FunctionItem = ({ icon, label, description }) => {
  return (
    <div className="tw-max-w-[380px] tw-h-[220px] tw-flex tw-items-center tw-justify-center">
      <div>
        <img className="tw-mb-[20px] tw-max-h-[100px]" src={icon} alt="icon" />

        <div className="tw-max-w-[210px]">
          <h3 className="tw-font-semibold tw-text-[20px] tw-mb-[20px]">{label}</h3>
          <p className="tw-text-[15px]">{description}</p>
        </div>
      </div>
    </div>
  );
};
export default function Functions() {
  return (
    <div className="tw-bg-white tw-pt-[80px] tw-pb-[80px]  lg:tw-pt-[100px] lg:tw-pb-[100px] desktop:tw-pt-[135px] desktop:tw-pb-[130px]">
      <div className=" tw-grid sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-y-[20px] tw-gap-x-[50px] tw-max-w-[1520px] tw-m-auto tw-p-[12px]">
        {ListFunction.map(({ id, icon, label, description }, index) => (
          <div
            key={id}
            className={classNames("tw-border-solid tw-border-greyBb ", {
              "lg:tw-border-r-[1px]": index + 1 !== ListFunction.length,
            })}
          >
            <FunctionItem icon={icon} label={label} description={description} />
          </div>
        ))}
      </div>
    </div>
  );
}
