import Footer from "src/components/Footer";
import Header from "src/components/Header/Header";
import ProductDetailView from "src/components/Products/ProductDetail/ProductDetailView";
import LayoutDocument from "src/layouts/LayoutDocument";

const ProductDetailPage = () => {
  return (
    <LayoutDocument title="Autoshine hệ thống rửa xe tự động tiêu chuẩn Đức">
      <Header />
      <ProductDetailView />
      <Footer />
    </LayoutDocument>
  );
};

export default ProductDetailPage;
