import Slider from "react-slick";
import { CardProducts } from "src/components/Home/OurProducts";
import Title from "src/components/Home/Title";
import { ListProducts } from "src/data/ListProduct";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  appendDots: (dots) => <ul>{dots}</ul>,
  customPaging: () => {
    return (
      <div className="custom-paging tw-bg-grey29 tw-h-[5px] tw-w-[60px] tw-cursor-pointer"></div>
    );
  },
  dotsClass: "tw-flex tw-justify-center tw-bg-grey29 tw-w-[fit-content] tw-mx-auto tw-mt-[50px] ",
};

export default function HotDeals({ title = "Ưu đãi hấp dẫn", data = ListProducts, type }) {
  return (
    <div className="hot-deals-page">
      <div className="tw-bg-no-repeat tw-bg-cover tw-flex tw-flex-col tw-text-center">
        <div className="tw-container tw-mx-auto lg:tw-pt-[140px] lg:tw-pb-[85px] md:tw-pt-[100px] md:tw-pb-[85px] tw-py-[60px]  tw-px-[20px]">
          <div>
            <Title
              styleTitle={{ fontSize: "40px", lineHeight: "40px", color: "white" }}
              title={title}
              subTitle="Autoshine"
              subTitleClass="!tw-text-[#e65613]"
              colorSubTitle="#e65613"
            />
          </div>
          <div>
            <Slider
              focusOnSelect
              {...settings}
              infinite={!!(data?.length > 2)}
              slidesToShow={data?.length > 3 ? 3 : data?.length}
              className="custom-slider"
            >
              {data.map((item) => {
                return <CardProducts type={type} key={item.id} data={item} />;
              })}
            </Slider>
          </div>
        </div>
      </div>
      {/* TODO: STYLE JSX */}
      <style jsx global>{`
        .hot-deals-page li.slick-active .custom-paging {
          background: #545454 !important;
        }
        .custom-slider .slick-track {
          display: flex;
          align-items: stretch;
        }
        .custom-slider .slick-slide {
          height: auto;
        }
        .custom-slider .slick-slide > div {
          height: 100%;
        }
      `}</style>
    </div>
  );
}
