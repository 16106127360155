import { Tabs } from "antd";
import useWindowSize from "src/hooks/useWindowSize";
export const TabName = ({ name }) => {
  return (
    <div className="tw-text-red lg:tw-text-[26px] sm:tw-text-[18px] tw-text-[14px] tw-leading-[30px]">
      {name}
    </div>
  );
};
export default function TabInfo({ data }) {
  const { width } = useWindowSize();
  return (
    <div>
      <Tabs
        centered
        className="custom-tabs tw-text-red"
        tabBarGutter={width > 1024 ? 200 : width > 767 ? 80 : width > 479 ? 40 : 20}
      >
        <Tabs.TabPane tab=<TabName name="Mô tả" /> key="1">
          {/* <Description /> */}
          <div className="tw-container tw-mx-auto lg:tw-w-[860px] tw-text-white tw-mt-[55px] tw-text-[16px]">
            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab=<TabName name="Thông tin chi tiết" /> key="2">
          <div className="tw-container tw-mx-auto lg:tw-w-[860px] tw-text-white tw-mt-[55px] tw-text-[16px]">
            <div dangerouslySetInnerHTML={{ __html: data?.detail }} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab=<TabName name="Hướng dẫn sử dụng" /> key="3">
          <div className="tw-container tw-mx-auto lg:tw-w-[860px] tw-text-white tw-mt-[55px] tw-text-[16px]">
            <div dangerouslySetInnerHTML={{ __html: data?.tutorial }} />
          </div>
        </Tabs.TabPane>
      </Tabs>
      <style jsx global>{`
        .custom-tabs .ant-tabs-ink-bar {
          background: #bd1300 !important;
          height: 7px !important;
        }
        .custom-tabs .ant-tabs-nav:before {
          border-bottom: 1px solid #bd1300;
        }
      `}</style>
    </div>
  );
}
