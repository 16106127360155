import React from "react";
// import { RightCircleOutlined } from "@ant-design/icons";
import Background from "src/assets/images/bg-6.jpg";

export default function BookAnAppointment() {
  // const handleBooking = () => {
  //   global.phonePopupRef.current.checkOpen();
  // };
  return (
    <div
      className="tw-bg-no-repeat tw-bg-cover"
      style={{ backgroundImage: `url(${Background.src})` }}
    >
      <div className="tw-container tw-mx-auto tw-text-center tw-text-white lg:tw-py-[100px] md:tw-py-[60px] tw-py-[30px]">
        <h2 className=" lg:tw-text-[52px] lg:tw-leading-[58px] md:tw-text-[42px] md:tw-leading-[46px] tw-text-[24px] tw-leading-[28px] tw-text-white lg:tw-mb-[8px] tw-mb-[10px]">
          Đặt Hẹn Ngay Với AutoShine
        </h2>
        <div className="lg:tw-text-[24px] lg:tw-leading-[26px] lg:tw-mb-[42px] md:tw-text-[18px] md:tw-leading-[20px] tw-mb-[26px] tw-text-[12px] tw-leading-[16px] tw-mb-[20px]">
          Với hệ thống đặt lịch rửa xe hàng đầu Việt Nam
        </div>
        <p className="lg:tw-text-[18px] lg:tw-leading-[20px] lg:tw-mb-[24px] md:tw-text-[16px] md:tw-leading-[18px] md:tw-mb-[20px] tw-text-[12px] tw-leading-[16px] tw-mb-[16px] md:tw-whitespace-pre-wrap">
          {`Đặt lịch rửa xe chỉ trong 1 phút. Xe của bạn sẽ được ưu tiên rửa xe ngay khi đến AutoShine trong thời gian đặt lịch`}
        </p>
        {/* <button
          className=" tw-mx-auto lg:tw-gap-[6px] tw-gap-[10px] tw-flex tw-items-center tw-justify-center md:tw-h-[60px] tw-h-[40px] md:tw-py-[20px] md:tw-px-[36px] tw-py-[8px] tw-px-[20px] md:tw-text-[24px] tw-text-[18px] md:tw-leading-[24px] tw-leading-[20px] tw-text-white tw-bg-button tw-rounded-full tw-uppercase"
          onClick={handleBooking}
        >
          Đặt Ngay
          <RightCircleOutlined />
        </button> */}
      </div>
    </div>
  );
}
