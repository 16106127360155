import Product1 from "src/assets/images/product_1.png";
import Product2 from "src/assets/images/product_2.png";
import Product3 from "src/assets/images/product_3.png";

export const ListProducts = [
  {
    id: 1,
    image: Product1,
    title: "Tire Car Wheel",
    description: "Lorem ipsum dolor sit amet, adipisicing elit",
    price: 3250000,
  },
  {
    id: 2,
    image: Product2,
    title: "Tire Car Wheel",
    description: "Lorem ipsum dolor sit amet, adipisicing elit",
    price: 3250000,
  },
  {
    id: 3,
    image: Product3,
    title: "Tire Car Wheel",
    description: "Lorem ipsum dolor sit amet, adipisicing elit",
    price: 3250000,
  },
  {
    id: 4,
    image: Product1,
    title: "Tire Car Wheel",
    description: "Lorem ipsum dolor sit amet, adipisicing elit",
    price: 3250000,
  },
  {
    id: 5,
    image: Product2,
    title: "Tire Car Wheel",
    description: "Lorem ipsum dolor sit amet, adipisicing elit",
    price: 3250000,
  },
  {
    id: 6,
    image: Product3,
    title: "Tire Car Wheel",
    description: "Lorem ipsum dolor sit amet, adipisicing elit",
    price: 3250000,
  },
];
